<template>
    <h4 class="mb-3">Logging in</h4>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  async mounted () {
    const guardianId = this.$route.params.guardianId
    const token = this.$route.params.token
    if (!Array.isArray(guardianId) && !Array.isArray(token)) {
      const response = await this.$api.tokenLogin(parseInt(guardianId), token)
      if (response.guardian) {
        this.$store.commit('success', { success: 'E-mail verified.', preserve: true })
        this.$router.replace({ name: 'home' })
      }
    }
  }
})
</script>
